import React from 'react'
import {Cta} from '../cta/Cta'
import {Feature} from '../feature/Feature'
import {Header} from '../header/Header'
import {Hero} from '../hero/Hero'
import {Section} from '../section2/Section'
import {Statistics} from '../statistics/Statistics'
import {Testimonials} from '../testimonials/Testimonials'
import {Why} from '../why/Why'
import s from './App.module.scss'

import 'antd/dist/antd.css';
import { Footer } from '../footer/Footer'

function App() {
  return (
    <div className={s.app}>
      <section className={s.main}>
        <Header/>
        <Hero/>
      </section>
      <Why/>
      <Statistics/>
      <Feature/>
      <Section/>
      <Testimonials/>
      <Cta/>
      <Footer/>
    </div>
  )
}

export default App
